<template>
  <div class="container no-select">
    <el-row :gutter="10">
      <el-col :md="9" :lg="6" :xl="4">
        <div class="card mb-0" v-loading="loading">
          <el-button
            class="float-right"
            type="primary"
            size="small"
            @click="addItem"
          >
            <el-icon><plus /></el-icon>
            <span>添加检测项目</span>
          </el-button>
          <div class="card-head">检测项目</div>
          <el-scrollbar height="calc(100vh - 75px)">
            <div
              class="group-item"
              v-for="item in group"
              :key="'group_' + item.id"
              @click="getItem(item)"
            >
              <div>{{ item.name }}</div>
              <div>
                <el-tag v-if="item.script" type="success" effect="dark"
                  >自动校验</el-tag
                >
                <el-tag v-else type="info" effect="dark">手动校验</el-tag>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :md="15" :lg="18" :xl="20">
        <el-scrollbar height="calc(100vh - 20px)" v-loading="update">
          <template v-if="item.model">
            <div class="card">
              <template v-if="item.model === 'edit'">
                <el-button
                  class="float-right ml-10"
                  type="primary"
                  @click="saveUpdate"
                  >保存更新</el-button
                >
                <el-button
                  class="float-right"
                  type="danger"
                  :disabled="item.number > 0"
                  @click="removeItem"
                >
                  <span v-if="item.number > 0">已使用,无法删除</span>
                  <span v-else>删除项目</span>
                </el-button>
              </template>
              <template v-else>
                <el-button class="float-right" type="primary" @click="saveAdd"
                  >保存项目</el-button
                >
                <el-button class="float-right mr-10" plain @click="cleanItem"
                  >取消</el-button
                >
              </template>
                <el-button class="float-right" plain @click="addIndex(item)"
                  >添加指标</el-button
                >
              <div class="card-head">
                <span v-if="item.model === 'edit'">编辑项目</span>
                <span v-else>新增项目</span>
              </div>
              <el-form label-width="75px">
                <el-form-item label="项目组名">
                  <el-input v-model="item.name" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="排序权重">
                  <el-input-number v-model="item.weight" :min="0" :max="9" />
                </el-form-item>
                <el-form-item label="校验脚本">
                  <el-input
                    type="textarea"
                    resize="none"
                    v-model="item.script"
                    :rows="10"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <el-row :gutter="10" style="margin-right: 0">
              <el-col
                :md="24"
                :lg="12"
                :xl="8"
                v-for="(sub, i) in item.list"
                :key="'index_' + sub.id"
              >
                <div class="card">
                  <el-button
                    class="float-right"
                    type="danger"
                    :icon="deleteIcon"
                    @click="removeIndex(item.list, i)"
                    :disabled="sub.number > 0"
                    circle
                  ></el-button>
                  <el-form label-width="75px">
                    <el-form-item label="指标名称">
                      <el-input
                        v-model="sub.label"
                        placeholder="必填"
                        style="width: 95px"
                      ></el-input>
                      <div class="ml-10 mr-10" style="color: #606266">代码</div>
                      <el-input
                        v-model="sub.name"
                        placeholder="必填"
                        style="width: 125px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="提示信息">
                      <el-input
                        v-model="sub.tips"
                        style="width: 200px"
                      ></el-input>
                      <div class="ml-10 mr-10" style="color: #606266">单位</div>
                      <el-input
                        v-model="sub.unit"
                        style="width: 85px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="输入类型">
                      <el-select
                        v-model="sub.input"
                        placeholder="请选择类型(必选)"
                      >
                        <el-option label="字符 text" :value="1" />
                        <el-option label="整数 number" :value="2" />
                        <el-option label="小数 digit" :value="3" />
                      </el-select>
                      <div class="ml-10 mr-10" style="color: #606266">
                        日常指标
                      </div>
                      <el-switch v-model="sub.state"></el-switch>
                    </el-form-item>
                    <el-form-item label="长度限制" class="mb-0">
                      <el-input-number
                        v-model="sub.length"
                        :min="1"
                        :max="11"
                      />
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </template>
          <el-empty
            v-else
            description="请先选择具体的检测项目"
            style="margin-top: calc(50vh - 200px)"
          ></el-empty>
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { Delete, Plus } from "@element-plus/icons";
import { index } from "../../plugin/api";

export default {
  name: "Test",
  components: { Plus },
  data: () => ({
    loading: true,
    update: false,
    cache: "",
    group: [],
    item: {},
    deleteIcon: markRaw(Delete),
  }),
  methods: {
    init() {
      this.getData();
    },
    getData() {
      this.loading = true;
      index
        .getList()
        .then((res) => {
          setTimeout(() => {
            this.group = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    getItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      for (let i in this.item.list) {
        this.item.list[i].state = this.item.list[i].type === 1;
      }
      this.item.model = "edit";
      this.cache = JSON.stringify(this.item);
    },
    cleanItem() {
      this.item = {
        weight: 0,
      };
    },
    addItem() {
      if (this.item.model !== "add") {
        this.cleanItem();
        this.item.model = "add";
      }
    },
    addIndex(item) {
      if (item.list) item.list.push({ id: 0, length: 5 });
      else item.list = [{ id: 0, length: 5 }];
    },
    removeIndex(list, i) {
      this.$confirm("删除后需点击上方的‘保存’按钮才可生效.", "删除选项", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        list.splice(i, 1);
        list = JSON.parse(JSON.stringify(list));
      });
    },
    checkForm() {
      let form = this.item;
      if (!form.name) return this.showWarning("项目组名不能为空");
      if (form.list == undefined || form.list.length == 0)
        return this.showWarning("检测项目必须包含指标");
      for (let i in form.list) {
        let item = form.list[i];
        if (!item.label) return this.showWarning("指标名称不能为空");
        if (!item.name) return this.showWarning("指标代码不能为空");
        if (item.input == undefined)
          return this.showWarning("输入类型不能为空");
      }
      return true;
    },
    saveUpdate() {
      if (this.checkForm()) {
        if (JSON.stringify(this.item) === this.cache)
          return this.showWarning("项目内容没有变动");
        let old = JSON.parse(this.cache);
        let form = {};
        let addList = [];
        let updateList = [];
        let deleteList = [];
        for (let key in this.item) {
          if (old[key] != this.item[key]) {
            if (key === "list") {
              for (let a in old.list) {
                let exist = false;
                for (let b in this.item.list) {
                  if (old.list[a].id === this.item.list[b].id) {
                    exist = true;
                    if (
                      JSON.stringify(old.list[a]) !=
                      JSON.stringify(this.item.list[b])
                    ) {
                      this.item.list[b].type = this.item.list[b].state ? 1 : 2;
                      updateList.push(this.item.list[b]);
                    }
                  }
                }
                if (!exist) deleteList.push(old.list[a].id);
              }
              for (let a in this.item.list) {
                if (this.item.list[a].id === 0) {
                  this.item.list[a].type = this.item.list[a].state ? 1 : 2;
                  addList.push(this.item.list[a]);
                }
              }
            } else form[key] = this.item[key];
          }
        }
        form.id = this.item.id;
        this.$confirm(
          "您对 “" + this.item.name + "” 检测项目进行了编辑,请确认无误再提交",
          "保存变更",
          {
            confirmButtonText: "确认保存",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.update = true;
          index
            .updateItem(form, addList, updateList, deleteList)
            .then((res) => {
              this.update = false;
              if (res.state) {
                if (addList.length > 0) this.cleanItem();
                this.getData();
                this.$message.success({
                  message: "保存成功",
                  center: true,
                });
              } else {
                this.showWarning(res.message ? res.message : "保存失败");
              }
            })
            .catch(() => {
              this.update = false;
            });
        });
      }
    },
    saveAdd() {
      if (this.checkForm()) {
        this.$confirm(
          "您创建了新的 “" + this.item.name + "” 检测项目,请确认无误再提交",
          "新增项目",
          {
            confirmButtonText: "确认添加",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.update = true;
          for (let i in this.item.list) {
            this.item.list[i].type = this.item.list[i].state ? 1 : 2;
          }
          index
            .addItem(this.item)
            .then((res) => {
              this.update = false;
              if (res.state) {
                this.cleanItem();
                this.getData();
                this.$message.success({
                  message: "添加成功",
                  center: true,
                });
              } else {
                this.showWarning(res.message ? res.message : "添加失败");
              }
            })
            .catch(() => {
              this.update = false;
            });
        });
      }
    },
    removeItem() {
      this.$confirm(
        "确认要删除 “" + this.item.name + "” 检测项目? 删除后无法恢复.",
        "删除项目",
        {
          confirmButtonText: "确认删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.update = true;
        index
          .removeItem(this.item.id)
          .then((res) => {
            this.update = false;
            if (res.state) {
              this.cleanItem();
              this.getData();
              this.$message.success({
                message: "删除成功",
                center: true,
              });
            } else {
              this.showWarning(res.message ? res.message : "删除失败");
            }
          })
          .catch(() => {
            this.update = false;
          });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.group-item {
  justify-content: space-between;
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  padding: 5px;
}

.group-item:last-child {
  margin-bottom: 0;
}

.group-item:hover {
  background-color: #eeeded;
}

.group-item:active {
  background-color: #e2e1e1;
}
</style>
